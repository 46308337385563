"use client";

import { FilterType } from "@/components/shared/filters";
import { useDocIdContext } from "@/lib/doc-id-context";
import axios from "axios";
import debounce from "lodash.debounce";
import { usePathname } from "next/navigation";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useSWR from "swr";
import { v4 as uuidv4 } from "uuid";

const fetcher = (url: string) => axios.get(url).then((res) => res.data);

const SearchDialogContext = createContext<{
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  search: string;
  setSearch: (search: string) => void;
  initialMessages: any[];
  setInitialMessages: Dispatch<SetStateAction<any[]>>;
  workflowMapping: Record<number, any>;
  setWorkflowMapping: Dispatch<SetStateAction<Record<number, any>>>;
  docIds: string[];
  uniqueSearchResultDocIds: string[];
  uniqueSearchResultDealIds: string[];
  uniqueSearchResultDealDocIds: any[];
  activeSearchResult: any;
  setActiveSearchResult: (activeSearchResult: any) => void;
  tab: "documentChunks" | "verifiedAnswers" | "deal";
  setTab: (tab: "documentChunks" | "verifiedAnswers" | "deal") => void;
  searchScope: "deal" | "knowledge_base";
  setSearchScope: (searchScope: "deal" | "knowledge_base") => void;
  showChat: boolean;
  setShowChat: (showChat: boolean) => void;
  handleKeyDown: (event: any) => void;
  chatMessagesRef: React.RefObject<HTMLDivElement>;
  actualSearch: string;
  debouncedSearch: any;
  documentChunks: any[];
  documentChunksLoading: boolean;
  verifiedAnswers: any[];
  verifiedAnswersLoading: boolean;
  dealSearch: any;
  dealSearchLoading: boolean;
  deal: any;
  productLabelFilters: any[];
  setProductLabelFilters: Dispatch<SetStateAction<any[]>>;
  productLabelFilterType: FilterType;
  setProductLabelFilterType: Dispatch<SetStateAction<FilterType>>;
  labelFilters: string[];
  setLabelFilters: Dispatch<SetStateAction<string[]>>;
  labelFilterType: FilterType;
  setLabelFilterType: Dispatch<SetStateAction<FilterType>>;
  documentFilters: any[];
  setDocumentFilters: Dispatch<SetStateAction<any[]>>;
  documentFilterType: FilterType;
  setDocumentFilterType: Dispatch<SetStateAction<FilterType>>;
  filterView: "product_label" | "label" | "document" | null;
  setFilterView: Dispatch<
    SetStateAction<"product_label" | "label" | "document" | null>
  >;
  salesforceCustomPropertiesAgg: any;
  hubspotCustomPropertiesAgg: any;
}>({
  docIds: [],
  actualSearch: "",
  debouncedSearch: () => {},
  search: "",
  documentChunks: [],
  documentChunksLoading: false,
  verifiedAnswers: [],
  verifiedAnswersLoading: false,
  dealSearch: {},
  dealSearchLoading: false,
  showModal: false,
  setShowModal: () => {},
  initialMessages: [],
  setInitialMessages: () => {},
  workflowMapping: {},
  setWorkflowMapping: () => {},
  uniqueSearchResultDocIds: [],
  uniqueSearchResultDealIds: [],
  uniqueSearchResultDealDocIds: [],
  activeSearchResult: {},
  setActiveSearchResult: () => {},
  tab: "documentChunks",
  setTab: () => {},
  searchScope: "knowledge_base",
  setSearchScope: () => {},
  showChat: false,
  setShowChat: () => {},
  handleKeyDown: () => {},
  chatMessagesRef: { current: null },
  deal: {},
  setSearch: () => {},
  productLabelFilters: [],
  setProductLabelFilters: () => {},
  productLabelFilterType: "include",
  setProductLabelFilterType: () => {},
  labelFilters: [],
  setLabelFilters: () => {},
  labelFilterType: "include",
  setLabelFilterType: () => {},
  documentFilters: [],
  setDocumentFilters: () => {},
  documentFilterType: "is",
  setDocumentFilterType: () => {},
  filterView: null,
  setFilterView: () => {},
  salesforceCustomPropertiesAgg: {},
  hubspotCustomPropertiesAgg: {},
});

export const SearchDialogContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [initialMessages, setInitialMessages] = useState<any[]>([
    {
      id: uuidv4(),
      role: "assistant",
      content: "Hi there! I'm Paige, your DealPage assistant. How can I help?",
      createdAt: new Date().toISOString(),
    },
  ]);

  const [workflowMapping, setWorkflowMapping] = useState<Record<number, any>>(
    {},
  );
  const { docIds } = useDocIdContext();
  const pathname = usePathname();
  const parts = pathname?.split("/");
  const { data: deal } = useSWR(
    parts?.[1] === "deals" && parts?.[2]?.startsWith("deal_")
      ? `/api/deals/${parts?.[2]}?columns=id,name,clients(*)`
      : null,
    fetcher,
  );
  const [searchScope, setSearchScope] = useState<"deal" | "knowledge_base">(
    "knowledge_base",
  );
  useEffect(() => {
    if (typeof window !== "undefined") {
      setSearchScope(
        (localStorage?.getItem("searchScope") as "deal" | "knowledge_base") ||
          "deal",
      );
    }
  }, []);
  useEffect(() => {
    if (showModal) {
      axios.post("/api/search-chunks-combined", {
        query: "warm up lambda",
        match_count: 1,
      });
    }
  }, [showModal]);
  const [showChat, setShowChat] = useState(false);
  const handleKeyDown = useCallback(
    (event: any) => {
      if (event.key === "Enter" && event.metaKey) {
        setShowChat(true);
        // @ts-ignore
        chatMessagesRef?.current?.appendMessage(search);
      }
    },
    [search],
  );
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);
  const [productLabelFilters, setProductLabelFilters] = useState<any[]>([]);
  const [labelFilters, setLabelFilters] = useState<string[]>([]);
  const [documentFilters, setDocumentFilters] = useState<any[]>([]);
  const [productLabelFilterType, setProductLabelFilterType] =
    useState<FilterType>("include");
  const [labelFilterType, setLabelFilterType] = useState<FilterType>("include");
  const [documentFilterType, setDocumentFilterType] =
    useState<FilterType>("include");
  const chatMessagesRef = useRef<HTMLDivElement>(null);
  const [actualSearch, setActualSearch] = useState<string>("");
  const debouncedSearch = useMemo(() => {
    return debounce((query: string) => {
      setActualSearch(query?.trim());
    }, 500);
  }, []);
  useEffect(() => {
    debouncedSearch(search);
    return debouncedSearch.cancel;
  }, [search]);
  const { data: documentChunks, isLoading: documentChunksLoading } = useSWR(
    actualSearch
      ? `/api/search-chunks?query=${actualSearch}&productLabels=${JSON.stringify(
          productLabelFilters?.map((p) => p.id),
        )}&labels=${JSON.stringify(
          labelFilters,
        )}&documentFilters=${JSON.stringify(documentFilters?.map((d) => d.id))}`
      : null,
    (url: string) =>
      axios
        .post(url, {
          match_count: 25,
          query: actualSearch,
          docIds: docIds,
          bucket: "library_documents",
          product_labels: productLabelFilters,
          labels: labelFilters,
          document_filters: documentFilters,
        })
        .then((res) => res.data),
  );
  const { data: verifiedAnswers, isLoading: verifiedAnswersLoading } = useSWR(
    actualSearch ? `/api/search-verified-answers?query=${actualSearch}` : null,
    fetcher,
  );
  const { data: dealSearch, isLoading: dealSearchLoading } = useSWR(
    actualSearch ? `/api/search-all?query=${actualSearch}` : null,
    (url: string) =>
      axios
        .post(url, {
          query: actualSearch,
          docIds: docIds,
          bucket: "both",
        })
        .then((res) => res.data),
  );

  const uniqueSearchResultDocIds: string[] = useMemo(() => {
    return Array.from(
      new Set(documentChunks?.map((result: any) => result.document_id) || []),
    );
  }, [documentChunks]);
  const uniqueSearchResultDealIds: string[] = useMemo(() => {
    const docIdToDealId = dealSearch?.dealDocs?.reduce((acc: any, doc: any) => {
      acc[doc.id] = doc.deal_id;

      return acc;
    }, {});
    const chunkDealIds =
      dealSearch?.dealDocumentChunks?.map(
        (chunk: any) => docIdToDealId[chunk.document_id],
      ) || [];
    return Array.from(
      new Set(
        dealSearch?.deals?.map((result: any) => result.id).concat(chunkDealIds),
      ),
    );
  }, [dealSearch]);
  const uniqueSearchResultDealDocIds: any[] = useMemo(() => {
    const docIdToDealId = dealSearch?.dealDocs?.reduce((acc: any, doc: any) => {
      acc[doc.id] = doc.deal_id;

      return acc;
    }, {});
    return Array.from(
      new Set(
        dealSearch?.dealDocumentChunks?.map(
          (result: any) => result.document_id,
        ),
      ),
    ).map((docId: any) => ({
      id: docId,
      deal_id: docIdToDealId[docId],
    }));
  }, [dealSearch]);
  const [activeSearchResult, setActiveSearchResult] = useState<any>();
  const [tab, setTab] = useState<"documentChunks" | "verifiedAnswers" | "deal">(
    "documentChunks",
  );
  const [filterView, setFilterView] = useState<
    "product_label" | "label" | "document" | null
  >(null);

  const { data: customProperties, isLoading: customPropertiesLoading } = useSWR(
    `/api/custom-properties`,
    fetcher,
  );

  return (
    <SearchDialogContext.Provider
      value={{
        showModal,
        setShowModal,
        search,
        setSearch,
        initialMessages,
        setInitialMessages,
        workflowMapping,
        setWorkflowMapping,
        docIds,
        uniqueSearchResultDocIds,
        uniqueSearchResultDealIds,
        uniqueSearchResultDealDocIds,
        activeSearchResult,
        setActiveSearchResult,
        tab,
        setTab,
        searchScope,
        setSearchScope,
        showChat,
        setShowChat,
        handleKeyDown,
        chatMessagesRef,
        actualSearch,
        debouncedSearch,
        documentChunks,
        documentChunksLoading,
        verifiedAnswers,
        verifiedAnswersLoading,
        dealSearch,
        dealSearchLoading,
        deal,
        productLabelFilters,
        setProductLabelFilters,
        productLabelFilterType,
        setProductLabelFilterType,
        labelFilters,
        setLabelFilters,
        labelFilterType,
        setLabelFilterType,
        documentFilters,
        setDocumentFilters,
        documentFilterType,
        setDocumentFilterType,
        filterView,
        setFilterView,
        salesforceCustomPropertiesAgg:
          customProperties?.salesforce_custom_properties,
        hubspotCustomPropertiesAgg: customProperties?.hubspot_custom_properties,
      }}
    >
      {children}
    </SearchDialogContext.Provider>
  );
};

export function useSearchDialogContext() {
  return useContext(SearchDialogContext);
}
